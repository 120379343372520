<style>
	.full-screen {
		width: 100%;
		height: 100%;
		background: #f0f2f5;
	}
	.sidebar {
		box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
		border: 0px;
	}
	.sidemenu {
		border: 0px !important;
	}
	.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
		border-bottom: 2px solid #fff;
	}
</style>
<template>
	<el-container class="full-screen">
		<el-header height="64px" style="padding:0;background:#001529;box-shadow: 0 1px 4px rgba(0,21,41,.08);">
			<el-menu mode="horizontal" :default-active="default_active" router background-color="#001529" text-color="#ffffffa6" active-text-color="#1890ff" style="border:0">
				<el-menu-item index="" style="height:64px;">
					<!-- <img src="../../assets/logo.png" style="width: 42px;margin: 0px 16px 0px 0;" /> -->
					<span v-if="company_name=='中铝中州铝业有限公司'" style="color: #fff;margin: 16px 0;font-size: 20px;letter-spacing: 1px;color:#fff">中铝中州铝业能碳管理中心平台</span>
					<span v-else style="color: #fff;margin: 16px 0;font-size: 20px;letter-spacing: 1px;color:#fff">综合能源管控云平台</span>

				</el-menu-item>
				<template v-for="(item,index) in menu_list">
					<el-submenu v-if="item.hasChildren" :key="'#'+index" :index="'#'+index">
						<template slot="title"><i :class="item.icon"></i> <span>{{item.title}}</span></template>
						<el-menu-item v-for="(item2,index2) in item.children" :key="'#'+index+'-'+index2" :index="item2.link">▪ <span>{{item2.title}}</span></el-menu-item>
					</el-submenu>
					<el-menu-item v-else :key="'#'+index" :index="item.link"><i :class="item.icon"></i> <span>{{item.title}}</span></el-menu-item>
				</template>
				<el-submenu index="2" style="float:right">
					<template slot="title">
						<el-avatar :src="head" size="small"></el-avatar> {{nickname}}
					</template>
					<el-menu-item index="#22" @click="set_info"><i class="el-icon-setting"></i> 个人设置</el-menu-item>
					<el-menu-item index="#23" @click="logout"><i class="el-icon-switch-button"></i> 退出登录</el-menu-item>
				</el-submenu>
			</el-menu>
		</el-header>
		<el-main :style="main_style">
			<router-view :key="router_view_key" />
		</el-main>
		<mdform title="个人信息" :detail_action=detail_action :write_action=write_action :writeFormShow.sync="writeFormShow" :writeFormRules="{}">
			<template #writeForm="{writeForm}">
				<el-row>
					<el-form-item prop="id" label="id" v-show="false">
						<el-input v-model="writeForm.id" />
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="上传头像" prop="head">
						<mupload-avatar :photo.sync="writeForm.head" action="http://zhny.rayoai.com/api/admin/User/upload_photo" />
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item prop="nickname" label="姓名">
						<el-input v-model="writeForm.nickname" />
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="性别" prop="sex">
						<el-radio-group v-model="writeForm.sex">
							<el-radio-button label="1">男</el-radio-button>
							<el-radio-button label="2">女</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item prop="birthday" label="出生日期">
						<el-date-picker v-model="writeForm.birthday" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right" style="width: 159px;">
						</el-date-picker>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item prop="mobile" label="手机号">
						<el-input v-model="writeForm.mobile" />
					</el-form-item>
					<el-form-item prop="email" label="邮箱">
						<el-input v-model="writeForm.email" />
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item prop="job" label="岗位">
						<el-input v-model="writeForm.job" />
					</el-form-item>
					<el-form-item prop="title" label="职称">
						<el-input v-model="writeForm.title" />
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item prop="pwd" label="密码">
						<el-input v-model="writeForm.pwd" />
					</el-form-item>
				</el-row>
			</template>
		</mdform>

	</el-container>
</template>
<script>
	import request from "../../plugins/axios";
	import mdform from "../../components/mdform.vue";
	import muploadAvatar from "../../components/form/muploadAvatar.vue";
	export default {
		name: "layout",
		//加载通用组件、搜索表单+表格+分页组件
		data() {
			let role = window.localStorage.getItem("role");
			let company_name = window.localStorage.getItem("company_name");
			let menu_list = [
				{ link: "index", icon: "iconfont el-icon-dapingmu", title: "首页" },
				{ link: "stat", icon: "iconfont el-icon-dian", title: "综合监控" },
				{ link: "data", icon: "iconfont el-icon-fenxi1", title: "能源数据" },
				{ link: "report", icon: "iconfont el-icon-fenxi", title: "能源报表" },
				{
					link: "warning",
					icon: "iconfont el-icon-icon-alarm",
					title: "报警中心",
				},
			];
			if (role == "企业管理员") {
				if (company_name == "中铝中州铝业有限公司") {
					menu_list = [
						{ link: "stat", icon: "iconfont el-icon-dian", title: "综合监控" },
						{ link: "co2", icon: "iconfont el-icon-dian", title: "能碳排放" },
						{ link: "board", icon: "iconfont el-icon-dian", title: "能耗看板" },
						{ link: "data", icon: "iconfont el-icon-fenxi1", title: "能源数据" },
						{ link: "report", icon: "iconfont el-icon-fenxi", title: "能源报表" },
						{
							link: "warning",
							icon: "iconfont el-icon-icon-alarm",
							title: "报警中心",
						},
					];
				} else {
					menu_list = [
						{ link: "stat", icon: "iconfont el-icon-dian", title: "综合监控" },
						{ link: "data", icon: "iconfont el-icon-fenxi1", title: "能源数据" },
						{ link: "report", icon: "iconfont el-icon-fenxi", title: "能源报表" },
						{
							link: "warning",
							icon: "iconfont el-icon-icon-alarm",
							title: "报警中心",
						},
					];
				}
			}
			if (role == "超级管理员") {
				menu_list.push({
					link: "powercut",
					icon: "iconfont el-icon-baobiao",
					title: "停电感知",
				});
				menu_list.push({
					link: "stat2",
					icon: "iconfont el-icon-baobiao",
					title: "环境监测",
				});
				menu_list.push({
					link: "base",
					icon: "iconfont el-icon-xitong",
					title: "系统设置",
				});
			}
			return {
				main_style: {},
				isCollapse: false,
				role: window.localStorage.getItem("role"),
				nickname: window.localStorage.getItem("nickname"),
				head: window.localStorage.getItem("head"),
				company_name: window.localStorage.getItem("company_name"),
				menu_list: menu_list,
				writeFormShow: false,
				aside_width: "256px",
				detail_action:
					"/admin/User/detail/" + window.localStorage.getItem("admin_id"),
				write_action:
					"/admin/User/edit/" + window.localStorage.getItem("admin_id"),
			};
		},
		components: {
			mdform,
			muploadAvatar,
		},

		computed: {
			//使不同url路由到相同组件,也可重新渲染(key不同)
			router_view_key() {
				if (
					this.$route.path == "/admin/index" ||
					this.$route.path == "/admin/stat" ||
					this.$route.path == "/admin/room" ||
					this.$route.path == "/admin/room1" ||
					this.$route.path == "/admin/room2" ||
					this.$route.path == "/admin/powercut" ||
					this.$route.path == "/admin/stat1" ||
					this.$route.path == "/admin/stat2" ||
					this.$route.path == "/company/index" ||
					this.$route.path == "/company/stat" ||
					this.$route.path == "/company/co2" ||
					this.$route.path == "/company/board" ||
					this.$route.path == "/company/room" ||
					this.$route.path == "/company/room1" ||
					this.$route.path == "/company/room2" ||
					this.$route.path == "/company/powercut" ||
					this.$route.path == "/company/stat1" ||
					this.$route.path == "/company/stat2"
				) {
					this.main_style = {
						padding: 0,
					};
				} else {
					this.main_style = {};
				}
				return this.$route.path + new Date().getTime();
			},
			default_active() {
				return this.$route.name;
			},
		},
		methods: {
			// show_aside() {
			// 	this.isCollapse = !this.isCollapse;
			// 	if (this.isCollapse) {
			// 		this.aside_width = "64px";
			// 	} else {
			// 		this.aside_width = "256px";
			// 	}
			// },
			set_info() {
				this.writeFormShow = true;
			},
			logout() {
				let token = window.localStorage.getItem("token");
				request({
					url: "/admin/user/logout",
					data: {
						token: token,
					},
				}).then(
					(ret) => {
						if (ret.code == 1) {
							this.handleLogout();
						} else {
							this.handleLogout();
						}
					},
					(err) => {
						this.handleLogout();
					}
				);
			},
			handleLogout() {
				window.localStorage.removeItem("admin_id");
				window.localStorage.removeItem("token");
				window.localStorage.removeItem("role");
				window.localStorage.removeItem("nickname");
				window.localStorage.removeItem("head");
				this.$router.push("/");
			},
		},
	};
</script>