<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
		object-fit: cover;
	}
</style>
<template>
<el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-success="handlePhotoSuccess">
  <img v-if="photo" :src="photo" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
</template>
<script>
	export default {
    name:'muploadAvatar',
    props:{
      photo:{
        type:String,
        default:""
			},
			action:{
				type:String,
				default:""
			}
    },
		data() {
			return {
      }
    },
    methods:{
      handlePhotoSuccess(ret, file) {
        this.$emit('update:photo', ret.data.photo)
			}
    }
  }
</script>