<template>
	<el-dialog :title="title" :visible.sync="is_show">
		<el-form ref="writeForm" :model="writeForm" :rules="writeFormRules" :inline="true" label-width="150px" size="small">
			<slot name="writeForm" :writeForm="writeForm" />
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="write_info()" size="small">提交</el-button>
			<el-button @click="resetWriteForm" size="small">重置</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import request from "../plugins/axios.js";
	//添加/编辑表单
	export default {
		name: "mdform",
		props: {
			detail_action: {
				type: String,
				default: ""
			},
			write_action: {
				type: String,
				default: ""
			},
			title: {
				type: String,
				default: "添加/编辑"
			},
			writeFormShow: {
				type: Boolean,
				default: false
			},
			writeFormRules: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				writeForm: {},
				is_show: this.writeFormShow
			};
		},

		watch: {
			writeFormShow(val, oldVal) {
				if (val === oldVal) {
					return false;
				}
				if (val) {
					// 重置表单
					this.resetWriteForm();
					// 获取数据
					if (this.detail_action != "") {
						this.get_info();
					}
				}
				this.is_show = val;
			},
			// 如果内部有新值变化，更新外部值
			is_show(val, oldVal) {
				if (val === oldVal) {
					return false;
				}
				this.$emit("update:writeFormShow", val);
			}
		},
		methods: {
			//清空表单
			resetWriteForm() {
				if (this.$refs["writeForm"] !== undefined) {
					this.$refs["writeForm"].resetFields();
					this.writeForm = {};
				}
			},
			//获取详情数据
			get_info() {
				request({
					url: this.detail_action,
					data: {}
				}).then(
					ret => {
						if (ret.code == 1) {
							// this.$notify({
							//   title: '操作成功',
							//   message: msg,
							//   type: 'success'
							// });
							this.writeForm = ret.data;
						} else {
							this.$notify.error({
								title: "获取数据失败",
								message: ret.msg
							});
						}
					},
					err => {
						this.$notify.error({
							title: "请求异常",
							message: err
						});
					}
				);
			},
			//添加/编辑数据
			write_info() {
				let post = {};
				Object.assign(post, this.writeForm);
				request({
					url: this.write_action,
					data: post
				}).then(
					ret => {
						if (ret.code == 1) {
							this.$notify({
								title: "操作成功",
								message: "数据已更新",
								type: "success"
							});

							this.resetWriteForm();
							this.is_show = false;
							//TODO 刷新页面
							location.reload();
						} else {
							this.$notify.error({
								title: "提交失败",
								message: ret.msg
							});
						}
					},
					err => {
						this.$notify.error({
							title: "请求异常",
							message: err
						});
					}
				);
			}
		}
	};
</script>